import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, split, fromPromise, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import gql from 'graphql-tag';
import { getItemLocalStorage, setItemLocalStorage } from '../LocalStorage';
import { SUBSCRIBE_TO_SUPER_CHAT_MESSAGES } from './GraphqlEndpointConstants';

export let token = getItemLocalStorage('token');
export let wsSubscribed = false;
export const setWsSubscribed = (val: boolean): void => {
  wsSubscribed = val;
};
export const setToken = (tkn: string): void => {
    token = tkn;
  };

const endpoint = process.env.REACT_APP_ENDPOINT

console.log('endpoint used:'+endpoint);

// Helper functions
// Auth link to set the Authorization header
const authLink = setContext(async (_, { headers }) => {

console.log("token:"+token);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});


// WebSocket link for subscriptions using graphql-ws
const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${endpoint}/graphql`, // Your WebSocket server URL
    connectionParams: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
);

const httpLink = new HttpLink({
  uri: `https://${endpoint}`, // Your GraphQL server URL
});

// Use `split` to send data to each link depending on the operation kind
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // If operation is a subscription, use WebSocket
  httpLink // Otherwise, use the HTTP link
);

// Create Apollo Client instance
//13.232.223.204
const client = new ApolloClient({
  link: from([authLink, splitLink]),
  cache: new InMemoryCache(),
});

export default client;
